<template>
    <CModal
      :title="title"
      :color="color"      
      :show.sync="AccountModal"
      class="basic-admin-modal-color"
    >   
        <CForm>
            <CInput
                label="Nombre:"
                :lazy="false"
                :value.sync="$v.form.name.$model"
                :isValid="checkIfValid('name')"
                placeholder=""
                autocomplete="given-name"
                invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
            />
            <CRow>
              <CCol md="6">
                <CInput
                    label="Correo Electrónico"
                    type="email"
                    :lazy="false"
                    :value.sync="$v.form.email.$model"
                    :isValid="checkIfValid('email')"
                    placeholder=""
                    :disabled="isEdit"
                    autocomplete="email"
                    invalidFeedback="Este campo es requerido y debe ser un correo electrónico válido."
                />
              </CCol>            
              <CCol md="6">
                <CInput
                    label="Teléfono"
                    :isValid="checkIfValid('phone')"
                    :lazy="false"
                    :value.sync="$v.form.phone.$model"
                    placeholder=""
                    autocomplete="Teléfono"
                    invalidFeedback="Este campo es requerido."
                />
              </CCol>            
            </CRow>
            <CRow>
                <CCol md="6">
                <CInput
                    :isValid="checkIfValid('password')"
                    :value.sync="$v.form.password.$model"
                    label="Contraseña"
                    type="password"
                    placeholder=""
                    :disabled="isEdit"
                    autocomplete="new-password"
                    invalidFeedback="Debe tener al menos 8 caracteres y teniendo al menos un número, una mayúscula y una minúscula."
                />
                </CCol>
                <CCol md="6">
                <CInput
                    :isValid="checkIfValid('confirmPassword')"
                    :value.sync="$v.form.confirmPassword.$model"
                    label="Confirmar Contraseña"
                    type="password"
                    placeholder=""
                    :disabled="isEdit"
                    autocomplete="new-password"
                    invalidFeedback="Las contraseñas no coinciden."
                />
                </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="store" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import { validationMixin } from "vuelidate"
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"

export default {
  name: 'UserModal',
  props: {

  },
  data () {
    return {
      AccountModal: false,
      title: "Nueva Cuenta",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      roles: [],
      rolesOptions: []
    }
  },
  mounted: async function() {

  },
  methods: {
    storeModal () {
      this.AccountModal = true;
      this.color = "info";
      this.title = 'Nueva Cuenta';
      this.isEdit = false;
      this.submitted = false;

      this.form = this.getEmptyForm();
    },
    updateModal (item) {
      this.AccountModal = true;
      this.color = "warning";
      this.title = 'Editar Cuenta';
      this.isEdit = true;
      this.submitted = false;

      this.form = {
          id: item.id,
          name: item.name,
          email: item.email,
          phone: item.phone,
          password: 'Password07',
          confirmPassword: 'Password07'
      };
    },
    store () {
      this.$v.$touch();

      if (this.isValid) {
        this.submitted = true;

        if(this.form.id > 0){
          this.$emit("update", this.form );
        }
        else{
          this.$emit("store", this.form );
        }

        this.closeModal();
      }
    },
    closeModal () {
      this.AccountModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
          email: '',
          phone: '',
          password: '',          
          confirmPassword: ''
      }
    },
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      
      return !(field.$invalid || field.$model === '')
    },
  },
  computed: {
    formString () { return JSON.stringify(this.form, null, 4) },
    isValid () { return !this.$v.form.$invalid },
    isDirty () { return this.$v.form.$anyDirty },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3)
      },
      email: {
        required,
        email
      },
      phone: {
        required
      },
      password: {
        required,
        minLength: minLength(8),
        strongPass: helpers.regex('strongPass', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
}
</script>
<style>
.basic-admin-modal-color .modal-header{
  background-color: #2F3841 !important;
}
</style>