<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-copy"/> {{caption}}
        <div class="card-header-actions">
          <CButton color="info" size="sm" @click="storeModal">Nueva Cuenta</CButton>
        </div>
      </slot>
    </CCardHeader>
    <CCardBody>
      <CRow>        
        <CCol md="12">
          <!-- FILTROS -->
          <CRow>
              <CCol md="2">
                <div role="group" class="form-group" style="margin: -10px 0px 10px">
                  <label> Estado:</label>
                  <CSelect
                    :value.sync="statusSelect" 
                    :options="statusOptions"
                    @change="changeFilters"
                  />                        
                </div>
              </CCol>                            
          </CRow>     
        </CCol>
      </CRow>
      <CRow>
        <CCol md="12">
          <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="items"
            :fields="fields"
            :items-per-page="small ? 30 : 10"
            :dark="dark"
            pagination
            :table-filter="{label: 'Buscar: ', placeholder: '...'}"
            class="listing"
          >
            <template #name="{item}"><td class="text-center">{{ item.name }}</td></template>
            <template #email="{item}"><td class="w150 text-center">{{ item.email}}</td></template>
            <template #phone="{item}"><td class="w150 text-center">{{ item.phone}}</td></template>
            <template #active="{item}">
              <td class="w150 text-center">
                <template v-if="item.active == 1">
                  <CBadge color="success">Activo</CBadge>
                </template>
                <template v-else>
                  <CBadge color="danger">Inactivo</CBadge>
                </template>
              </td>
            </template>
            <template #actions="{item}">
              <td class="table_actions b3">
                <CButton v-if="item.active" color="warning" size="sm" @click="updateModal(item)"><CIcon name="cil-pencil"/></CButton>
                <CButton v-if="item.active" color="dark" size="sm" @click="inactiveModal(item)"><CIcon name="cil-trash"/></CButton>
                <CButton v-if="!item.active" color="success" size="sm" @click="activeModal(item)"><CIcon name="cil-check-circle"/></CButton>
              </td>
            </template>
          </CDataTable>
        </CCol>
      </CRow>
    </CCardBody>
    <AccountModal ref="accountModal" @store="storeProcess" @update="updateProcess"></AccountModal>
    <DeleteModal ref="deleteModal" @confirm="inactiveProcess"></DeleteModal>
  </CCard>
</template>

<script>
import store from '../../store'
import AccountModal from './AccountModal.vue'
import DeleteModal from '../global/ConfirmModal.vue'
import ws from '../../services/account';

export default {
  name: 'AccountsTable',
  components: {
    AccountModal,
    DeleteModal
  },
  props: {
    items: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      fields: [
        {key: 'name', label: 'Nombre de la Cuenta'},
        {key: 'email', label: 'Correo'},
        {key: 'phone', label: 'Teléfono'},
        {key: 'active', label: 'Estado'},
        {key: 'actions', label: 'Acciones', class: 'actions' },
      ],
      idToDelete: "",
      moduleDelete: "",
      statusSelect: "",
      statusOptions: [{"label": "Todos","value":""},{"label": "Activos","value":1},{"label": "Inactivos","value":0}],
    }
  },
  mounted: async function() {
  },
  methods: {
    async changeFilters () {      
      let filters = this.getFilters();
      this.$emit("refreshItems", filters);
    },
    getFilters(){
      let filters = {
        status: this.statusSelect,
      };

      return filters;
    },
    storeModal () {
      this.$refs.accountModal.storeModal();
    },
    updateModal (item) {
      this.$refs.accountModal.updateModal(item);
    },
    async storeProcess(data) {
      let response = await ws.store(data);
      
      if(response.type == "success"){
        this.$emit("refreshItems", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async updateProcess(data) {
      let response = await ws.update(data);

      if(response.type == "success"){
        this.$emit("refreshItems", response.data );
      }

      this.showToast(response.type, response.message);
    },
    async inactiveProcess() {
      let response;
      if(this.moduleDelete == "delete"){
        response = await ws.inactive({id: this.idToDelete});
      }else if (this.moduleDelete == "active"){
        response = await ws.active({id: this.idToDelete});
      }else{
        response = await ws.recoverPasswordLink({account_id: this.idToDelete});
      }
      
      if(response.type == "success"){
        this.$emit("refreshItems", response.data );
      }

      this.showToast(response.type, response.message);
    },
    inactiveModal (item) {    
      this.idToDelete = item.id;
      this.moduleDelete = "delete";
      let color = "dark";
      let title = "Desactivar Cuenta";
      let content = "¿Estás seguro que deseas desactivar la cuenta?"
      let confirm = "<span style='font-size: 16px;font-weight: bold;'>"+item.name +"</span>";
      this.$refs.deleteModal.show(color,title,content,confirm);
    },        
    activeModal (item) {    
      this.idToDelete = item.id;
      this.moduleDelete = "active";
      let color = "success";
      let title = "Activar Cuenta";
      let content = "¿Estás seguro que deseas activar la cuenta?"
      let confirm = "<span style='font-size: 16px;font-weight: bold;'>"+item.name +"</span>";
      this.$refs.deleteModal.show(color,title,content,confirm);
    },        
    sendRecover (item) {    
      this.idToDelete = item.id;
      this.moduleDelete = "recover";
      let color = "warning";
      let title = "Recuperar Contraseña";
      let content = "¿Estás seguro que deseas empezar el proceso de recuperación para esta cuenta?"
      let confirm = "<span style='font-size: 16px;font-weight: bold;'>"+item.name +"</span>";
      this.$refs.deleteModal.show(color,title,content,confirm);
    },  
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  watch: {
    
  },
  computed: {

  }
}
</script>
